/* Custom script for Walt Disney World brand v2.0.0 */
/* Includes all devices (mobile/desktop) */
/* Author: Lindsay Prahalad/Tom Nelson, October 2015 */

// -------------------------------------------------------------------------------------------------------------------

// Global variables

// Setup brand variable
var brand = 'wdw'

// -------------------------------------------------------------------------------------------------------------------

// Modal dialogs

// Intermission

// Set character/nickname/fact
function intermissionData() {
// Set random character
var randomise = Math.floor(Math.random() * 10);
switch (randomise) {
case 0: character = 'buzz'; nickname = 'earthling'; break;
case 1: character = 'woody'; nickname = 'pardner'; break;
case 2: character = 'rex'; nickname = 'pal'; break;
case 3: character = 'alien'; nickname = 'earthling'; break;
case 4: character = 'sulley'; nickname = 'fella'; break;
case 5: character = 'mater'; nickname = 'ol\' buddy'; break;
case 6: character = 'merida'; nickname = 'wee one'; break;
case 7: character = 'dory'; nickname = 'little fella'; break;
case 8: character = 'nemo'; nickname = 'explorer'; break;
case 9: character = 'mr-potato-head'; nickname = 'spud'; break;
}
// Set random fact
var randomise = Math.floor(Math.random() * 11);
switch (randomise) {
case 0: fact = 'Walt Disney World covers around 40 square miles - almost the size of San Francisco!'; break;
case 1: fact = 'During the holidays there are over 1,500 Christmas trees around Walt Disney World Resort!'; break;
case 2: fact = 'Disney PhotoPass photographers take over 100,000 photos of our guests every day!'; break;
case 3: fact = 'Each year gardening staff maintain 4 million shrubs, 13,000 roses and more than 200 topiary!'; break;
case 4: fact = 'The Tree of Life at Disney\'s Animal Kingdom is home to over 300 detailed animal carvings!'; break;
case 5: fact = 'Expedition Everest at Disney\'s Animal Kingdom is our tallest attraction at almost 200 feet!'; break;
case 6: fact = 'Spaceship Earth, the visual and thematic centerpiece of Epcot, weighs over 7,250 tonnes!'; break;
case 7: fact = 'More than 30 tonnes of fruit and veg are grown in Epcot and served in our restaurants!'; break;
case 8: fact = 'On average 210 pairs of sunglasses are handed into the Lost &amp; Found every single day!'; break;
case 9: fact = 'The fountain at Epcot Innoventions Plaza can shoot water more than 150 feet in the air!'; break;
case 10: fact = 'Disney hotels use conservation lighting, so need just 10% the power of traditional bulbs!'; break;
}
}

// -------------------------------------------

// Prompt user upgrade to Hotel & Ticket package; user can disimss
function upgrade(saving) {
// Unbind the existing listener on .cancel
$('.cancel').unbind();
// Set up response variable for GA event
var response;
// Bind new listener to 'No Thanks' button
$('#upgrade .cancel').bind('click',function() { response = 'Decline'; });
// Bind new listener to 'Add Tickets' button 
$('#upgrade .button').bind('click',function() { response = 'Accept'; });
// Record upgrade selection in hidden field; delay form submission by 0.25 seconds to allow GAQ to fire
$('#upgrade input').bind('click',function() { var action = $('<input/>',{ type:'hidden',name:'action',value:response }); action.appendTo($(this)); GA.sendEvent('Book','Upgrade',response); setTimeout(function(){$('#form').submit();},250); });
// Set Combine & Save value
if (saving != null) { $('#saving').text(saving); }
// Dialog is dismissable
dismissable = true;
$('#dialog').addClass('dismissable');
// Add intermission class to dialog
$('#dialog').addClass('upgrade warning has-buttons');
// Show dialog
modal('#upgrade',false);
}

// -------------------------------------------

// Display overlay for on request rooms that can only be booked via the call centre; user can disimss
function request() {
// Dialog is dismissable
dismissable = true;
$('#dialog').addClass('dismissable');
// Track in GA
GA.sendEvent('Book','Enquiry','Request Room');
// Add request class to dialog
$('#dialog').addClass('request has-image');
// Show dialog
modal('#request',false);
}

// -------------------------------------------

// Display overlay for pre-registration rooms that can only be booked via the call centre; user can disimss
function register() {
// Dialog is dismissable
dismissable = true;
$('#dialog').addClass('dismissable');
// Track in GA
GA.sendEvent('Book','Enquiry','Pre-Registration Room');
// Add register class to dialog
$('#dialog').addClass('register has-image');
// Show dialog
modal('#register',false);
}

// -------------------------------------------

// Display overlay for Cruise and Stay
function cruise() {
// Dialog is dismissable
dismissable = true;
$('#dialog').addClass('dismissable');
// Track in GA
GA.sendEvent('Book','Enquiry','Cruise and Stay');
// Add cruise class to dialog
$('#dialog').addClass('cruise has-image');
// Show dialog
modal('#cruise-stay',false);
}

// -------------------------------------------

// Display overlay for ticket info; user can dismiss
function admission() {
// Dialog is dismissable
dismissable = true;
$('#dialog').addClass('dismissable');
// Set class
$('#dialog').addClass('information');
// Track view in GA
GA.sendEvent('Book','Enquiry','Disney Tickets');
// Show dialog
modal('#admission',true);
}

// -------------------------------------------

// Display verification overlay for ticket opt-out; user can dismiss
function verify() {
// Dialog is dismissable
dismissable = true;
$('#dialog').addClass('dismissable');
// Set class
$('#dialog').addClass('warning has-buttons');
// Track view in GA
GA.sendEvent('Book','Enquiry','Add Tickets');
// Show dialog
modal('#verify',false);
}

// -------------------------------------------

// Display overlay for dining info; user can dismiss
function dining() {
// Dialog is dismissable
dismissable = true;
$('#dialog').addClass('dismissable');
// Set class
$('#dialog').addClass('information');
// Track view in GA
GA.sendEvent('Book','Enquiry','Disney Dining Plan');	
// Show dialog
modal('#meals',true);
}

// -------------------------------------------------------------------------------------------------------------------

// Display overlay for dining info; user can dismiss
function mealOptions() {
// Dialog is dismissable
dismissable = true;
$('#dialog').addClass('dismissable');
// Set class
$('#dialog').addClass('information');
// Track view in GA
GA.sendEvent('Book','Enquiry','Meal Options');	
// Show dialog
modal('#meals',true);
}

// -------------------------------------------------------------------------------------------------------------------

// Submit interstitial after image has loaded
function interstitial() {
// Dialog is not dismissable
dismissable = false;
// Set body to position:fixed to force toolbars
fixBody(true);
// Get form action
var url = $('form').attr('action');
// Once image is loaded then redirect to results; meta-refresh will handle this if JavaScript fails
$('#intermission .image img').load(function() { setTimeout(function(){ window.location.replace(url); },1000); });
// Fallback if image fails to load within 3s
setTimeout(function(){ window.location.replace(url); },3000); 
}

// -------------------------------------------------------------------------------------------------------------------

// Geo-location

// Display overlay for geo-location selection
function geo() {
// Dialog is dismissable
dismissable = true;
$('#dialog').addClass('dismissable');
// Set class
$('#dialog').addClass('warning');
// Show dialog
modal('#geo',true);
}

// Geo-location switching
$('header .sublinks .geo').click(function(event) { geo(); });

// Show modal when geo-location in off-canvas nav is tapped (mobile only)
$('.mobile nav .geo').click(function() {
snap(); // Snap off-canvas closed
geo(); // Show popup
});

// Google Analytics: track geo-location switching
$('#geo a.geo, footer a.geo').click(function(event) {
// Prevent default
event.preventDefault();
// Track switch in GA
GA.sendEvent('Geolocation','Switch',$(this).attr('data-geo'));
// Get target URL
var href = $(this).attr('href');
// Redirect to target URL after .25 second delay
setTimeout(function(){ window.location = href; },250);
});

// -------------------------------------------------------------------------------------------------------------------

// Bookflow

// Google Analytics: track new searches with virtual pageview for funnel reporting
$('#itinerary a, #parameters a').click(function(event) { event.preventDefault(); _gaq.push(['_trackPageview','/walt-disney-world/book/new-search/']); var href = $(this).attr('href'); setTimeout(function(){ window.location = href; },250); });

// Manage popup for transfers
function transfers() {
GA.sendEvent('Book','Enquiry','Disney\'s Magical Express');	
warning('Disney\'s Magical Express.','Disney\'s Magical Express free transfer service is available exclusively to and from Orlando International (MCO) airport to selected Walt Disney World Resort hotels, but not from Orlando Sanford (SFB) or Tampa International (TPA) airports. Guests arriving at these airports will therefore have to make their own independent travel arrangements to Walt Disney World. Disney\'s Magical Express does not operate to Walt Disney World Swan and Dolphin hotels, or to other local hotels within the resort area.');
};

// Select flights
$('.flight .transfers a').click(function(event) { event.preventDefault(); transfers(); });
$(".flight .information a").click(function(event) { event.preventDefault(); $(this).toggleClass('open'); $(this).closest('hgroup').find('.details .itinerary').slideToggle(); }); 
$('.filter #direct').change(function(event) { setTimeout(function(){$('#form').submit();},500); });
if ($('#direct').prop('disabled')) { $('.filter fieldset').click(function() { warning('Why no direct flights?','Unfortunately we don\'t have any non-stop flights to Orlando or Tampa available from this airport. Not all airports offer direct services, so please review the indirect routes available, or try flying from a different airport.'); GA.sendEvent('Book','Enquiry','No Direct Flights'); }); }

// Track users switching to Hotel + Ticket package when flights are out of range
$('.availability a').click(function(event) { event.preventDefault(); GA.sendEvent('Book','Switch','Hotel + Ticket'); _gaq.push(['_trackPageview','/walt-disney-world/book/switch/from-flights/to-hotel-ticket/']); var href = $(this).attr('href'); setTimeout(function(){ window.location = href; },250); });

// Select room
if ($('.room').length) {
// Check action on room select
$('#page input[type="submit"], #page input[type="button"]').click(function(event) {
// Store room data in hidden field, where possible
$('input[type="hidden"][name="room"]').remove(); if (jQuery(this).hasAttr('name')) { var room = $('<input/>',{ type:'hidden',name:'room',value:$(this).attr('name') }); room.appendTo($(this)); };
// Check if room is 'on request' and prevent form submission
if (($(this).attr('data-request') == 'true')||($('input:radio:checked').attr('data-request') == 'true')) { event.preventDefault(); request(); }

// Check if room requires pre-registration and prevent form submission
else if (($(this).attr('data-register') == 'true')||($('input:radio:checked').attr('data-register') == 'true')) { event.preventDefault(); register(); }

// Prompt user to add tickets if on Hotel Only path; pass Combine & Save discount if applicable 
else if ($('#upgrade').length) { 
event.preventDefault(); 
var saving = null; 
if (jQuery(this).hasAttr('data-combine')) { saving = $(this).attr('data-combine'); } else if (jQuery('input:radio:checked').hasAttr('data-combine')) { saving = $('input:radio:checked').attr('data-combine'); }; upgrade(saving); }
// Display interstitial for Gold Medal flights; pass room type to hidden field for non-accessible rooms
else if ($('#flights').val() == 'GoldMedal') { event.preventDefault(); intermission(); form = $('#form'); delay = 500; submitForm(); };
});
// Collapse on unload
$(window).unload(function() { collapse(); }); $(window).bind('pagehide',function() { collapse(); });
};

// Meal plan options modal
$('#mealDetails').click(function() { 
	mealOptions(); 
});

// Select Tickets
if ($('#ticket').length) { 
$('.tickets a').click(function() { admission(); });
$('.no-tickets a').click(function(event) { event.preventDefault(); verify(); });
}

// Select Dining
$('.dining a').click(function() { dining(); })
$('.no-dining a').click(function(event) { event.preventDefault(); $('#form').submit(); })
$('a.dining-plan').click(function() { GA.sendEvent('Download','Dining',$(this).attr('data-plan')); })

// Select Extras
if ($('#extras').length) {
// Track Booker opt-outs in Google Analytics
$('#earm').change(function(){ if($(this).prop('checked') != true) { GA.sendEvent('Marketing','MyMagic+','Opt-Out'); } });
}

// Itinerary
$('.itinerary .transfers a').click(function(event) { event.preventDefault(); transfers(); });

// -------------------------------------------------------------------------------------------------------------------

// Static pages (e.g. non-bookflow)

// Cruise & Stay
$('#cruise .button').click(function(event) { event.preventDefault(); cruise(); });

// -------------------------------------------------------------------------------------------------------------------

// Planning tools

// Check date of birth
function validDoB() {
// Get now
var now = moment();
// Build DoB
var day = parseInt($('#body.planning #day').val());
var month = parseInt($('#body.planning #month').val());
var year = parseInt($('#body.planning #year').val());
// Do we have three valid date values?
if (day != NaN && month != NaN && year != NaN) {
// Convert to moment
var dob = moment([year,month-1,day]);
// Is this a valid date of birth?
if (dob.isValid()) {
// Does this DoB make the guest less than 18 years old?
if (dob.isAfter(now.subtract(18,'y'))) { warning('Sorry, you must be 18+.','You must be over 18 years old to request our free planning tools, so please ask a grown-up for help.'); }
}}
}

// Check valid DoB
$('#body.planning #day, #body.planning #month, #body.planning #year').change(function() { validDoB(); })

// Check valid country
$('#body.planning #country').change(function() {
// Non UK or Ireland?
if ($(this).val() == 'Rest of Europe') { warning('Sorry, we can\'t send planning tools to your address.','Our brochure and guidebook are only available to UK & Ireland households.'); }
})

// Track marcomms opt-in in Google Analytics
$('#body.planning #newsletter').change(function(){
if ($(this).prop('checked') == true) { GA.sendEvent('Marketing','Planning','Opt-In'); } // Opt-in
});

// Track order in Google Analytics
if ($("#body.planning").length) {
// Check if this is the confirmation screen and fire GA event
if ($("#body.planning").attr('data-step') == 'confirmation') { GA.sendEvent('Planning Tools','Order','Confirmation (1-Click)'); }
};

// -------------------------------------------------------------------------------------------------------------------

// On ready
$(document).ready(function() {

// Interstitial
if ($('body').hasClass('interstitial')) { interstitial(); };

});
